<template>
  <div class="ma-5">
    <div class="d-flex align-center my-3">
      <v-text-field v-model="filters.q" label="Search by name" solo prepend-inner-icon="mdi-magnify" flat outlined dense hide-details @change="getCustomers()" clearable @click:clear="filters.q = ''; getCustomers()" />
      <div>
        <v-badge inline :content="total + ''"></v-badge> customer(s) found
      </div>
      <v-spacer />
      <v-pagination v-model="filters.page" :length="pages" total-visible="5" @input="getCustomers()"></v-pagination>
      <v-btn color="green" dark small rounded @click="customerDialog.data = null; customerDialog.show = true">
        <v-icon small>mdi-plus</v-icon>
        add customer
      </v-btn>
    </div>
    <v-simple-table>
      <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Phone</th>
        <th>Invoices</th>
        <th>Updated at</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(customer, customerK) in customers" :key="customerK">
        <td>
          {{ customer.name }}
          <div class="text--secondary">
            {{ customer.firstName }} {{ customer.lastName }}
          </div>
        </td>
        <td>{{ customer.email }}</td>
        <td>{{ customer.phone }}</td>
        <td>{{ customer.invoicesCount }}</td>
        <td>{{ customer.updatedAt ? moment(customer.updatedAt).format('lll') : 'never' }}</td>
        <td>
          <v-btn icon small @click="customerDialog.data = customer; customerDialog.show = true">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </td>
      </tr>
      </tbody>
    </v-simple-table>
    <div class="d-flex align-center">
      <v-spacer />
      <v-pagination v-model="filters.page" :length="pages" total-visible="5" @input="getCustomers()"></v-pagination>
      <v-btn color="green" dark small rounded @click="customerDialog.data = null; customerDialog.show = true">
        <v-icon small>mdi-plus</v-icon>
        add customer
      </v-btn>
    </div>
    <v-dialog v-model="customerDialog.show" max-width="1000px" persistent>
      <v-toolbar flat>
        <v-toolbar-title>Edit customer</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="customerDialog.show = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-sheet class="pa-5">
        <CustomerForm v-if="customerDialog.show" v-model="customerDialog.data" @input="saveCustomer"/>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import globals from "../mixins/globals";
import CustomerForm from "../components/CustomerForm";
import functions from "../mixins/functions";

export default {
  name: "Customers",
  components: {CustomerForm},
  mixins: [globals, functions],
  data () {
    return {
      customers: [],
      filters: {
        page: 1,
        q: ''
      },
      pages: 1,
      total: 0,
      customerDialog: {
        show: false,
        data: null
      }
    }
  },
  created() {
    this.getCustomers()
  },
  methods: {
    saveCustomer () {
      axios.post(this.globals.apiUrl + '/customer/update',{
        customer: this.customerDialog.data
      })
      .then((res) => {
        if(res.data.isNew) {
          this.customers.unshift(res.data.customer);
        } else {
          this.customers.forEach((x, k) => {
            if(x.id === res.data.customer.id) {
              this.customers.splice(k, 1, res.data.customer)
            }
          })
        }

        this.notyf('Update success', 'success')
        this.customerDialog.show = false
      })
      .catch(() => {
        this.notyf('Update failed', 'error')
      })
    },
    getCustomers () {
      this.$store.commit('showLoading');
      axios
          .post(this.globals.apiUrl + '/customer/list', this.filters)
          .then((res) => {
            this.customers = res.data.customers
            this.pages = res.data.pages
            this.total = res.data.total
            this.$store.commit('hideLoading');
          })
    }
  }
}
</script>

<style scoped>

</style>
