<template>
  <div>
    <v-text-field
      v-model="addressLine1"
      label="Address line 1"
      @change="inp()"
    />
    <v-text-field
      v-model="addressLine2"
      label="Address line 2"
      @change="inp()"
    />
    <CustomerAddressAc
      v-model="postalCode"
      field="zip"
      label="Postal code"
      @change="inp()"
      @zip-selected="zipSelected"
    />
    <CustomerAddressAc
      v-model="city"
      field="city"
      label="City"
      @change="inp()"
      @zip-selected="zipSelected"
    />
    <v-select
      v-model="country"
      label="Country"
      :items="$store.state.countries"
      item-value="id"
      item-text="name"
      @change="inp()"
    />
    <v-select
      v-model="province"
      label="Province"
      :items="selectedCountry ? selectedCountry.provinces : []"
      item-value="id"
      item-text="name"
      @change="inp()"
    />
  </div>
</template>

<script>
import CustomerAddressAc from "./CustomerAddressAc";
export default {
  name: "CustomerAddress",
  components: { CustomerAddressAc },
  props: ["value"],
  computed: {
    selectedCountry() {
      let country = null;
      this.$store.state.countries.forEach(x => {
        if (x.id === this.country) {
          country = x;
        }
      });
      return country;
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.mapFromValue();
      }
    }
  },
  methods: {
    isValid() {
      const errors = [];

      if (!this.addressLine1 || this.addressLine1.trim().length === 0) {
        errors.push("Please fill address line 1");
      }

      if (!this.postalCode || this.postalCode.trim().length === 0) {
        errors.push("Please fill zip code");
      }

      if (!this.city || this.city.trim().length === 0) {
        errors.push("Please fill city");
      }
      if (!this.country) {
        errors.push("Please fill country");
      }
      if (!this.province) {
        errors.push("Please fill province");
      }

      return errors.length > 0 ? errors : true;
    },
    zipSelected(data) {
      this.postalCode = data.zip;
      this.city = data.city;
      this.country = 236;
      this.province = data.province.id;
      this.inp();
    },
    inp() {
      const keys = [
        "addressLine1",
        "addressLine2",
        "city",
        "postalCode",
        "country",
        "province"
      ];
      const d = {};
      keys.forEach(x => (d[x] = this[x]));
      this.$emit("input", d);
    },
    mapFromValue() {
      if (this.value) {
        const keys = [
          "addressLine1",
          "addressLine2",
          "city",
          "postalCode",
          "country",
          "province"
        ];
        keys.forEach(x => {
          if (this.value[x]) this[x] = this.value[x];
        });
      } else {
        this.inp();
      }
    }
  },
  data() {
    return {
      addressLine1: "",
      addressLine2: "",
      city: "",
      postalCode: "",
      country: null,
      province: null
    };
  }
};
</script>

<style scoped></style>
