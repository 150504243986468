<template>
  <div>
    <v-tabs background-color="blue-grey lighten-5">
      <v-tab>Contact</v-tab>
      <v-tab-item class="pt-5">
        <v-text-field v-model="customer.name" label="Customer" />
        <v-text-field v-model="customer.email" label="Email" />
        <v-text-field v-model="customer.phone" label="Phone" />
        <v-text-field v-model="customer.firstName" label="First name" />
        <v-text-field v-model="customer.lastName" label="Last name" />
      </v-tab-item>

      <v-tab>Billing</v-tab>
      <v-tab-item class="pt-5" eager>
        <v-select
          v-model="customer.currency"
          label="Currency"
          :items="$store.state.currencies"
          item-value="id"
          item-text="name"
        ></v-select>
        <CustomerAddress v-model="customer.address" ref="billingAddress" />
      </v-tab-item>

      <v-tab>Shipping</v-tab>
      <v-tab-item class="pt-5">
        <v-checkbox
          v-model="specifyShipping"
          label="Specify shipping address"
        />
        <div v-if="customer.shippingDetails">
          <v-text-field label="Name" v-model="customer.shippingDetails.name" />
          <CustomerAddress
            v-model="customer.shippingDetails.address"
            ref="shippingAddress"
          />
          <v-text-field
            label="Phone"
            v-model="customer.shippingDetails.phone"
          />
          <v-textarea
            label="Instructions"
            v-model="customer.shippingDetails.instructions"
          />
        </div>
      </v-tab-item>

      <v-tab>
        More
      </v-tab>
      <v-tab-item class="pt-5">
        <v-text-field label="Fax" v-model="customer.fax" />
        <v-text-field label="Toll Free" v-model="customer.tollFree" />
        <v-text-field label="Website" v-model="customer.website" />
        <v-textarea label="Notes" v-model="customer.internalNotes" />
      </v-tab-item>
    </v-tabs>

    <v-btn @click="save">Save</v-btn>
  </div>
</template>

<script>
import CustomerAddress from "./CustomerAddress";
import functions from "../mixins/functions";
export default {
  name: "CustomerForm",
  components: { CustomerAddress },
  props: ["value"],
  mixins: [functions],
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (this.value) {
          if (this.value.name) {
            this.customer.name = this.value.name;
          }
          if (this.value.id) {
            this.customer.id = this.value.id;
          }
          if (this.value.email) {
            this.customer.email = this.value.email;
          }
          if (this.value.phone) {
            this.customer.phone = this.value.phone;
          }
          if (this.value.firstName) {
            this.customer.firstName = this.value.firstName;
          }
          if (this.value.lastName) {
            this.customer.lastName = this.value.lastName;
          }
          if (this.value.currency) {
            this.customer.currency = this.value.currency;
          }
          if (this.value.address) {
            this.customer.address = this.value.address;
          }
          if (this.value.shippingDetails) {
            this.customer.shippingDetails = this.value.shippingDetails;
          }
          if (this.value.fax) {
            this.customer.fax = this.value.fax;
          }
          if (this.value.tollFree) {
            this.customer.tollFree = this.value.tollFree;
          }
          if (this.value.website) {
            this.customer.website = this.value.website;
          }
          if (this.value.internalNotes) {
            this.customer.internalNotes = this.value.internalNotes;
          }
        }
      }
    },
    specifyShipping(val) {
      if (val) {
        this.customer.shippingDetails = {
          name: "",
          address: null,
          phone: "",
          instructions: ""
        };
      } else {
        this.customer.shippingDetails = null;
      }
    }
  },
  methods: {
    save() {
      if (!this.customer.name || this.customer.name.trim().length === 0) {
        this.notyf("Enter customer name", "error");
        return;
      }

      if (this.$refs.billingAddress.isValid() !== true) {
        this.notyf(this.$refs.billingAddress.isValid()[0], "error");
        return;
      }
      if (
        this.$refs.shippingAddress &&
        this.$refs.shippingAddress.isValid() !== true
      ) {
        this.notyf(this.$refs.shippingAddress.isValid()[0], "error");
        return;
      }

      this.$emit("input", this.customer);
    }
  },
  data() {
    return {
      specifyShipping: false,
      customer: {
        id: null,
        name: "",
        email: "",
        phone: "",
        firstName: "",
        lastName: "",
        currency: null,
        address: null,
        shippingDetails: null,
        fax: "",
        tollFree: "",
        website: "",
        internalNotes: ""
      }
    };
  }
};
</script>

<style scoped></style>
