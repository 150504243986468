<template>
  <div
    v-click-outside="
      () => {
        showChoice = false;
      }
    "
  >
    <v-text-field
      :label="label"
      @change="inp()"
      @click="
        showChoice = true;
        getItems();
      "
      @input="getItems"
      v-model="val"
      :dense="$props.dense"
      :hide-details="$props.hideDetails"
      :outlined="$props.outlined"
    ></v-text-field>
    <div
      v-if="showChoice"
      style="overflow-y: auto; max-height: 200px; width: 100%; border: 1px solid #000"
    >
      <v-progress-linear indeterminate v-if="loading" />
      <v-list>
        <v-list-item
          v-for="(item, itemK) in items"
          :key="itemK"
          @click="itemSelected(item)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item[field] }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ item[field === "zip" ? "city" : "zip"] }},
              {{ item.province.name }}
              <div>Counties: {{ item.counties.join(", ") }}</div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import globals from "../globals";

export default {
  name: "CustomerAddressAc",
  props: ["label", "value", "field", "dense", "outlined"],
  data() {
    return {
      loading: false,
      val: this.value,
      items: [],
      showChoice: false
    };
  },
  methods: {
    itemSelected(item) {
      this.showChoice = false;
      this.val = item[this.field];
      this.inp();
      this.$emit("zip-selected", item);
    },
    inp() {
      this.$emit("input", this.val);
    },
    getItems() {
      if (this.loading) return;

      this.loading = true;

      axios
        .get(globals.apiUrl + "/zips?q=" + this.val)
        .then(res => {
          this.items = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  watch: {
    value(val) {
      this.val = val;
    }
  }
};
</script>

<style scoped></style>
